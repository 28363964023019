import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import type { Device } from '~/types/models'

export const useSearchStore = defineStore('search', () => {
  const { $api, $yandexSearchApi } = useNuxtApp()

  const list = ref<Device[]>([])
  const isDisabled = ref(true)
  const isLoading = ref(true)

  async function search(text: string) {
    isLoading.value = true

    return $yandexSearchApi.search(text, 1)
      .then(async (data) => {
        if (!data.documents) {
          list.value = []
          isLoading.value = false
        }

        const ids = data.documents.map((document: { url: string }) =>
          document.url.slice(document.url.lastIndexOf('/') + 1),
        )

        $api.devices.getDevices({ Ids: ids })
          .then((data) => {
            list.value = data.data
            isLoading.value = false
          })
          .catch((error: Error) => {
            list.value = []
            throw error
          })
      })
      .catch(() => {
        list.value = []
        isLoading.value = false
      })
  }

  return {
    isDisabled,
    isLoading,
    list,
    search,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}